







































import { Vue, Component } from "vue-property-decorator";
import ConnectionsService from "@/services/ConnectionsService";
import ConnectionConfig from "@/entity/Connection";
import { formatUnixTimestamp } from "@/utils/date";

@Component
export default class SettingsView extends Vue {
  connections: ConnectionConfig[] = [];
  showSkeleton = true;
  isTableLoading = false;
  formatUnixTimestamp = formatUnixTimestamp;

  async fetchConnections(): Promise<any> {
    try {
      this.isTableLoading = true;
      this.connections = await ConnectionsService.getConnections();
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToLoad"));
    } finally {
      this.isTableLoading = false;
    }
  }

  async created(): Promise<void> {
    await this.fetchConnections();
    setTimeout(() => {
      this.showSkeleton = false;
    }, 1000);
  }
}
